import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { PrivateRouteWrapper, PublicRouteWrapper } from './common/CustomRoutes'
import ConsultationsList from './pages/ConsultationsList/ConsultationsList'
import LayoutLogin from './pages/layout/LayoutLogin/LayoutLogin'
import Login from './components/Login/Login'
import Sidebar from './pages/layout/Sidebar/Sidebar'
import UsersList from './pages/UsersList/UsersList'
import Settings from './pages/Settings/Settings'
import PatientsList from './pages/PatientsList/PatientsList'
import PatientConsultations from './pages/PatientConsultations/PatientConsultations'
import ScrollToTop from './common/ScrollToTop/ScrollToTop'
import CompaniesList from './pages/CompanyList/CompanyList'
import DoctorsList from './pages/DoctorsList/DoctorsList'

const routes = ({ isAuthenticated }) => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <PublicRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/sign-in"
          layout={LayoutLogin}
          component={Login}
        />

        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/users"
          layout={Sidebar}
          component={UsersList}
        />

        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/companies"
          layout={Sidebar}
          component={CompaniesList}
        />

        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/doctors"
          layout={Sidebar}
          component={DoctorsList}
        />
        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/settings"
          layout={Sidebar}
          component={Settings}
        />

        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/patients"
          layout={Sidebar}
          component={PatientsList}
        />

        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/patients/:patientId/consultations"
          layout={Sidebar}
          component={PatientConsultations}
        />

        <PrivateRouteWrapper
          isAllowed={isAuthenticated}
          exact
          path="/"
          layout={Sidebar}
          component={ConsultationsList}
        />
      </Switch>
    </Router>
  )
}

export default routes
