import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import DateAdapter from '@mui/lab/AdapterMoment'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import { validateEmail } from '../../utils/auth'

const styles = {
  textField: {
    marginBlock: { sm: '10px', xs: '0px' },
  },
  buttonGroup: {
    margin: '0 auto',
  },
  button: {
    margin: '10px',
  },
  grid: {
    marginBlock: { sm: '0px', xs: '10px' },
  },
  tooltip: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
}

const defaultError = {
  name: {
    error: false,
    message: '',
  },
  email: {
    error: false,
    message: '',
  },
  lastNameFather: {
    error: false,
    message: '',
  },
  lastNameMother: {
    error: false,
    message: '',
  },
  identityCard: {
    error: false,
    message: '',
  },
  cellPhone: {
    error: false,
    message: '',
  },
  birthday: {
    error: false,
    message: '',
  },
}

const emptyPatient = {
  email: '',
  name: '',
  lastNameFather: '',
  lastNameMother: '',
  identityCard: '',
  landline: '',
  cellPhone: '',
  birthday: moment(),
  gender: '',
}

const MESSAGE_TOOLTIP =
  'Para crear un paciente ingrese todos los campos requeridos(*) y un teléfono fijo o celular'

const ERROR_MESSAGES = {
  email: 'Email requerido',
  name: 'Nombre requerido',
  lastNameFather: 'Apellido paterno requerido',
  lastNameMother: 'Apellido materno requerido',
  identityCard: 'CI requerido',
  cellPhone: 'Numero de celular requerido',
  birthday: 'Fecha de nacimiento es requerido',
  gender: 'Genero es requerido',
}

const ModalPatient = ({ open, patient, onClose, onSubmit }) => {
  const initialPatient = {
    email: patient?.email || '',
    name: patient?.name || '',
    lastNameFather: patient?.lastNameFather || '',
    lastNameMother: patient?.lastNameMother || '',
    identityCard: patient?.identityCard || '',
    landline: patient?.landline || '',
    cellPhone: patient?.cellPhone || '',
    birthday: patient?.birthday || moment(),
    gender: patient?.gender || '',
  }

  const isLoading = useSelector(state => state.pageReducer.isLoading)

  const [patientFields, setPatientFields] = useState(initialPatient)
  const [validFields, setValidFields] = useState(defaultError)

  const matches = useMediaQuery('(max-width:575px)')
  const sizeInput = matches ? 'small' : 'normal'

  const handleFieldChange = prop => event => {
    const {
      target: { value },
    } = event

    if (
      prop === 'identityCard' ||
      prop === 'cellPhone' ||
      prop === 'landline'
    ) {
      if (Number(value) >= 0) {
        setPatientFields({
          ...patientFields,
          [prop]: value,
        })
      }
    } else {
      setPatientFields({
        ...patientFields,
        [prop]: value,
      })
    }
  }

  const handleBlur = prop => event => {
    const { target } = event

    const value = target?.value.trim()

    setValidFields({
      ...validFields,
      [prop]: defaultError[prop],
    })

    if (!value.length && prop !== 'email') {
      setValidFields({
        ...validFields,
        [prop]: {
          error: true,
          message: ERROR_MESSAGES[prop],
        },
      })
    } else {
      if (prop === 'email' && value.length && !validateEmail(value)) {
        setValidFields({
          ...validFields,
          [prop]: {
            error: true,
            message: 'Ingrese un email valido',
          },
        })
      }

      setPatientFields({
        ...patientFields,
        [prop]: value,
      })
    }
  }

  const disabledButton = () => {
    const {
      name,
      email,
      lastNameFather,
      lastNameMother,
      cellPhone,
      landline,
      identityCard,
      birthday,
      gender,
    } = patientFields

    return (
      name &&
      lastNameFather &&
      lastNameMother &&
      (cellPhone || landline) &&
      identityCard &&
      birthday &&
      (email.length ? validateEmail(email) : true) &&
      gender &&
      !isLoading
    )
  }

  const handleCloseModal = () => {
    onClose()
    setPatientFields(emptyPatient)
    setValidFields(defaultError)
  }

  const handleSubmit = async () => {
    if (await onSubmit(patientFields)) {
      setPatientFields(emptyPatient)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }} variant="h6" component="h2">
        {patient.id ? 'Editar Paciente' : 'Crear Paciente'}
        <Tooltip title={MESSAGE_TOOLTIP} sx={styles.tooltip}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.name.error}
              fullWidth
              helperText={validFields.name.message}
              required
              label="Nombre"
              placeholder="Ingrese el nombre"
              value={patientFields.name}
              onChange={handleFieldChange('name')}
              onBlur={handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                disableFuture
                label="Fecha de nacimiento"
                openTo="year"
                views={['year', 'month', 'day']}
                value={patientFields.birthday}
                onChange={date => {
                  setPatientFields({
                    ...patientFields,
                    birthday: date,
                  })
                }}
                renderInput={params => (
                  <TextField
                    sx={styles.textField}
                    fullWidth
                    required
                    size={sizeInput}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.lastNameFather.error}
              fullWidth
              helperText={validFields.lastNameFather.message}
              required
              label="Apellido Paterno"
              placeholder="Ingrese el apellido"
              value={patientFields.lastNameFather}
              onChange={handleFieldChange('lastNameFather')}
              onBlur={handleBlur('lastNameFather')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.lastNameMother.error}
              fullWidth
              helperText={validFields.lastNameMother.message}
              required
              label="Apellido Materno"
              placeholder="Ingrese el apellido"
              value={patientFields.lastNameMother}
              onChange={handleFieldChange('lastNameMother')}
              onBlur={handleBlur('lastNameMother')}
            />
          </Grid>
        </Grid>

        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              error={validFields.identityCard.error}
              fullWidth
              helperText={validFields.identityCard.message}
              required
              label="CI"
              placeholder="Ingrese el CI"
              value={patientFields.identityCard}
              onChange={handleFieldChange('identityCard')}
              onBlur={handleBlur('identityCard')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              fullWidth
              label="Celular"
              placeholder="Ingrese celular"
              value={patientFields.cellPhone}
              onChange={handleFieldChange('cellPhone')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              type="number"
              size={sizeInput}
              fullWidth
              label="Teléfono fijo"
              placeholder="Ingrese el teléfono"
              value={patientFields.landline}
              onChange={handleFieldChange('landline')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              sx={styles.textField}
              size={sizeInput}
              error={validFields.email.error}
              fullWidth
              helperText={validFields.email.message}
              label="Email"
              placeholder="Ingrese el email"
              value={patientFields.email}
              onChange={handleFieldChange('email')}
              onBlur={handleBlur('email')}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.grid} container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              required
              sx={styles.textField}
              fullWidth
              size={sizeInput}
            >
              <InputLabel>Género</InputLabel>
              <Select
                value={patientFields.gender}
                label="Género"
                onChange={handleFieldChange('gender')}
              >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Femenino</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.buttonGroup}>
        <Button
          sx={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          sx={styles.button}
          variant="contained"
          color="primary"
          disabled={!disabledButton()}
          onClick={handleSubmit}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalPatient.propTypes = {
  open: PropTypes.bool.isRequired,
  patient: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ModalPatient.defaultProps = {
  patient: {},
}

export default ModalPatient
