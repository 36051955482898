export const AREAS_PATH = 'areas'
export const CHANGE_PASSWORD_PATH = 'users/changePassword'
export const CHANGE_STATUS_PATH = 'changeStatus'
export const CONSULTATIONS_PATH = 'consultations'
export const CONSULTATION_PATH = consultationId =>
  `consultations/${consultationId}/v2`
export const LOGIN_PATH = 'auth/login'
export const PATIENTS_PATH = 'patients'
export const PATIENT_CONSULTATIONS_PATH = patientId =>
  `patients/${patientId}/consultations`
export const ROLES_PATH = 'roles'
export const SEARCH_PATIENT_PATH = 'searchPatient'
export const SUBSIDIARIES_PATH = 'subsidiaries'
export const TESTS_PATH = 'tests'
export const TEST_CONSULTATIONS_PATH = 'testConsultations'
export const USER_PATH = 'users'
export const TYPE_RESULT_PATH = 'typeResults'
export const COMPANIES_PATH = 'companies'
export const DOCTORS_PATH = 'doctors'
export const PDF_CONSULTATION_RESULTS_PATH = consultationId =>
  `pdf/consultationResult/${consultationId}`
export const PDF_CREDENTIALS_PATH = consultationId =>
  `pdf/credentials/${consultationId}`
export const PDF_LAB_ORDER_PATH = consultationId =>
  `pdf/labOrder/${consultationId}`
export const XLSX_CONSULTATION_RESULTS_PATH = companyId =>
  `xlsx/companies/${companyId}`
export const XLSX_DOCTOR_PATH = doctorId => `xlsx/doctors/${doctorId}`
export const GET_TYPE_RESULTS_PATH = testId =>
  `${TESTS_PATH}/${testId}/${TYPE_RESULT_PATH}`
export const GET_TYPE_RESULTS_PATH_BY_TEST_CONS = (
  testId,
  testConsultationId
) => `${TESTS_PATH}/${testId}/${TEST_CONSULTATIONS_PATH}/${testConsultationId}`
export const RECORD_RESULTS_PATH = testConsultationId =>
  `${TEST_CONSULTATIONS_PATH}/${testConsultationId}/results`
