import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import BiotechIcon from '@mui/icons-material/Biotech'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import BusinessIcon from '@mui/icons-material/Business'
import SettingsIcon from '@mui/icons-material/Settings'
import HailIcon from '@mui/icons-material/Hail'

import { logoutUser } from '../../../../slices/userReducer'
import ChangePassword from '../../../../components/User/ChangePassword'

import { ADMIN, WORKER } from '../../../../constants/rolesConstants'

const styles = {
  box: {
    padding: '10px',
  },
  name: {
    fontSize: { xs: '1rem', md: '1.2rem' },
    fontWeight: 600,
  },
  role: {
    fontSize: { xs: '0.75rem', md: '1rem' },
  },
  subsidiary: {
    fontSize: { xs: '0.65rem', md: '0.85rem' },
  },
  toolBar: {
    display: { xs: 'none', md: 'flex' },
  },
}

const ItemsSideBar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [openChangePassword, setOpenChangePassword] = useState(false)

  const logout = () => {
    window.localStorage.removeItem('token')
    dispatch(logoutUser())
    history.push('/sign-in')
  }

  const FIRST_LIST = [
    { name: 'Consultas', icon: <BiotechIcon />, to: '/' },
    { name: 'Pacientes', icon: <PeopleOutlineIcon />, to: '/patients' },
  ]
  const SECOND_LIST = [
    {
      name: 'Cambiar Contraseña',
      icon: <VpnKeyIcon />,
      action: () => setOpenChangePassword(true),
    },
    { name: 'Cerrar sesion', icon: <ExitToAppOutlinedIcon />, action: logout },
  ]

  const { name, lastNameFather } = useSelector(state => ({
    name: state.userReducer.user?.name,
    lastNameFather: state.userReducer.user?.lastNameFather,
  }))
  const role = useSelector(state => state.userReducer.user?.role)
  const subsidiary = useSelector(
    state => state.userReducer.user?.subsidiary?.name
  )

  if (role.name !== WORKER) {
    FIRST_LIST.push({
      name: 'Usuarios',
      icon: <AdminPanelSettingsIcon />,
      to: '/users',
    })
    FIRST_LIST.push({
      name: 'Ajustes',
      icon: <SettingsIcon />,
      to: '/settings',
    })
  }

  if (role.name === ADMIN) {
    FIRST_LIST.push({
      name: 'Empresas',
      icon: <BusinessIcon />,
      to: '/companies',
    })
    FIRST_LIST.push({
      name: 'Doctores',
      icon: <HailIcon />,
      to: '/doctors',
    })
  }

  const completeName = () => `${name} ${lastNameFather}`

  return (
    <div className="items-sidebar">
      <Toolbar sx={styles.toolBar} />
      <Divider />
      <Box sx={styles.box}>
        <Typography sx={styles.name}>{completeName()}</Typography>
        <Typography sx={styles.role}>{role.label}</Typography>
        <Typography sx={styles.subsidiary}>{subsidiary}</Typography>
      </Box>
      <Divider />
      <List>
        {FIRST_LIST.map(item => (
          <ListItem
            button
            component={NavLink}
            key={item.name}
            to={item.to}
            activeClassName="Mui-selected"
            exact
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {SECOND_LIST.map(item => (
          <ListItem button key={item.name} onClick={item.action}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
        <ChangePassword
          open={openChangePassword}
          onClose={() => setOpenChangePassword(false)}
        />
      </List>
    </div>
  )
}

export default ItemsSideBar
