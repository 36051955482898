import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { client } from '../../utils/client'
import { Button, Container, IconButton, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditButton from '@mui/icons-material/Edit'

import DataGrid from '../../components/DataGrid/DataGrid'
import DoctorModal from '../../components/DoctorModal/DoctorModal'
import { setLoading, showSnackbar } from '../../actions/pageActions'
import { DOCTORS_PATH } from '../../constants/endpointsConstants'
import DeleteDoctor from '../../components/Doctor/Doctor'
import RequestPageIcon from '@mui/icons-material/RequestPage'
import DoctorReportModal from '../../components/DoctorReportModal/DoctorReportModal'

const DoctorsList = () => {
  const dispatch = useDispatch()

  const [doctors, setDoctors] = useState([])
  const [loaderTable, setLoaderTable] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [doctor, setDoctor] = useState({})
  const [openReportModal, setOpenReportModal] = useState(false)

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 0.7, minWidth: 100 },
    {
      field: 'lastNameFather',
      headerName: 'Apellido Paterno',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'lastNameMother',
      headerName: 'Apellido Materno',
      flex: 0.7,
      minWidth: 150,
    },

    {
      field: 'discount',
      headerName: 'Descuento',
      flex: 0.6,
      minWidth: 100,
    },

    {
      field: 'medicalCenter',
      headerName: 'Centro Médico',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'phoneNumber',
      headerName: 'Teléfono',
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: 'notes',
      headerName: 'Notas',
      flex: 0.9,
      minWidth: 150,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: params => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleSelectDcotorReport(params.row)}
          >
            <RequestPageIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleSelectDoctor(params.row)}
          >
            <EditButton />
          </IconButton>
          <DeleteDoctor doctor={params.row} onDelete={removeDoctor} />
        </>
      ),
    },
  ]

  const handleLoading = value => {
    setLoaderTable(value)
    dispatch(setLoading(value))
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setDoctor({})
  }

  const handleSelectDoctor = doctor => {
    setDoctor(doctor)
    setOpenModal(true)
  }

  const handleCloseReportModal = () => {
    setOpenReportModal(false)
    setDoctor({})
  }

  const handleSelectDcotorReport = company => {
    setDoctor(company)
    setOpenReportModal(true)
  }

  const removeDoctor = doctorId => {
    const newList = doctors.filter(doctor => doctor.id !== doctorId)
    setDoctors(newList)
  }

  const handleCreateDoctor = async doctor => {
    let success = false
    handleLoading(true)

    try {
      const {
        data: { message, data: newDoctor },
      } = await client.post(DOCTORS_PATH, doctor)
      setDoctors([newDoctor, ...doctors])
      dispatch(showSnackbar(message, 'success'))
      handleCloseModal()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    handleLoading(false)
    return success
  }

  const handleEditDoctor = async doctorPayload => {
    let success = false
    handleLoading(true)

    try {
      const {
        data: { message, data: updatedDoctor },
      } = await client.put(`${DOCTORS_PATH}/${doctor?.id}`, doctorPayload)

      const listUpdate = doctors.map(item =>
        item.id === updatedDoctor.id ? updatedDoctor : item
      )
      setDoctors(listUpdate)
      dispatch(showSnackbar(message, 'success'))
      handleCloseModal()
      success = true
    } catch (error) {
      dispatch(showSnackbar(error?.response?.data?.message))
    }

    handleLoading(false)
    return success
  }

  useEffect(() => {
    handleLoading(true)
    client
      .get(DOCTORS_PATH)
      .then(response => {
        setDoctors(response.data.data)
        handleLoading(false)
      })
      .catch(error => {
        handleLoading(false)
        dispatch(showSnackbar())
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" sx={{ marginBlock: '25px' }}>
        Lista de Doctores
      </Typography>
      <div
        style={{
          marginBottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setOpenModal(true)}
        >
          Agregar
        </Button>
      </div>
      <DataGrid rows={doctors} columns={columns} loading={loaderTable} />

      {openModal && (
        <DoctorModal
          open={openModal}
          doctor={doctor}
          onClose={handleCloseModal}
          onSubmit={doctor?.id ? handleEditDoctor : handleCreateDoctor}
        />
      )}

      {openReportModal && (
        <DoctorReportModal
          open={openReportModal}
          doctor={doctor}
          onClose={handleCloseReportModal}
        />
      )}
    </Container>
  )
}

export default DoctorsList
