import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, TextField } from '@mui/material'
import { getRefRange } from '../../../utils/consultation'

const InputResult = ({
  disabled,
  error,
  typeResult,
  value,
  onChangeResult,
}) => {
  const getTypeInput = typeOfInput => {
    if (typeOfInput === 'number' || typeOfInput === 'float') {
      return 'number'
    }
    return 'string'
  }

  return (
    <TextField
      disabled={disabled}
      error={error}
      select={typeResult.type_of_input === 'selection'}
      type={getTypeInput(typeResult.type_of_input)}
      label={typeResult.name}
      helperText={getRefRange(typeResult)}
      fullWidth
      FormHelperTextProps={{
        style: { whiteSpace: 'break-spaces' },
      }}
      value={value}
      onChange={e => onChangeResult(typeResult.id, e.target.value)}
    >
      <MenuItem style={{ color: 'gray' }} value={''}>
        Ninguno
      </MenuItem>
      {JSON.parse(typeResult?.possible_values)?.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}

InputResult.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  typeResult: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.string,
  onChangeResult: PropTypes.func,
}

InputResult.defaultProps = {
  disabled: false,
  error: false,
  value: '',
  onChangeResult: () => {},
}

export default InputResult
